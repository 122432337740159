import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { System, SystemCondition, SystemFilter, SystemsConnection } from '@generated/types/graphql';
import { DeepPartial } from 'redux';
import { SortField, sortToExpr } from '@hooks/systems/constants';
import { UseQueryOptions } from 'react-query/types/react/types';
import { SystemWithStats } from '@hooks/systems/types';
import { apiErrorHandler } from '@utils';
import { useSystemsStats } from '@hooks/systems/useSystemsStats';
import { useMemo } from 'react';

export const useSystems = (
  args: {
    condition?: DeepPartial<SystemCondition>;
    filter?: DeepPartial<SystemFilter>;
    first?: number;
    offset?: number;
    orderBy?: [field: SortField, desc?: boolean][];
  },
  opts: UseQueryOptions<System[]> = {}
) => {
  const { data: systems, isFetching: isFetchingSystems } = useQuery<System[]>(
    [ReactQueryKey.System, 'useSystems', args],
    async () => {
      const { condition, filter, first = 10, offset = 0, orderBy } = args;

      try {
        const variables = {
          condition,
          filter,
          first,
          offset,
          orderBy: sortToExpr(orderBy)
        };

        return (
          await postGraphql<{ systemsConnection: SystemsConnection }>(
            gql`
              query SYSTEM_LIST(
                $condition: SystemCondition
                $filter: SystemFilter
                $first: Int
                $offset: Int
                $orderBy: [SystemsOrderBy!]
              ) {
                systemsConnection(
                  condition: $condition
                  filter: $filter
                  first: $first
                  offset: $offset
                  orderBy: $orderBy
                ) {
                  nodes {
                    id
                    createdAt
                    monitored
                    raw
                    providerId
                    status
                    operationalAt
                    address
                    addressForced
                    addressCity
                    addressState
                    addressStreet
                    addressZip
                    lastReportAt
                    connectionType
                    size
                    providerStatus
                    name
                    number
                    clientType
                    uuid
                    notes
                    installedAt
                    operationStatus

                    integration {
                      id
                      provider
                    }

                    project {
                      id
                    }
                  }
                }
              }
            `,
            variables
          )
        ).systemsConnection.nodes;
      } catch (error) {
        throw apiErrorHandler('Error fetching systems', error);
      }
    },
    {
      keepPreviousData: true,
      initialData: [],
      ...opts
    }
  );

  const { data: systemsStats, isFetching: isFetchingStats } = useSystemsStats(systems.map(({ id }) => id));

  return {
    data: useMemo(
      () =>
        systems.map(
          (system) =>
            ({
              ...system,
              productionYesterday: systemsStats.productionYesterday[system.uuid],
              productionWeek: systemsStats.productionWeek[system.uuid],
              productionMonth: systemsStats.productionMonth[system.uuid],
              productionYear: systemsStats.productionYear[system.uuid],
              productionLifetime: systemsStats.productionLifetime[system.uuid],
              consumptionYesterday: systemsStats.consumptionYesterday[system.uuid],
              consumptionWeek: systemsStats.consumptionWeek[system.uuid],
              consumptionMonth: systemsStats.consumptionMonth[system.uuid],
              consumptionYear: systemsStats.consumptionYear[system.uuid],
              consumptionLifetime: systemsStats.consumptionLifetime[system.uuid],
              peakPower: systemsStats.peakPower[system.uuid]
            }) as SystemWithStats
        ),
      [systems, systemsStats]
    ),
    isFetching: useMemo(() => isFetchingSystems || isFetchingStats, [isFetchingSystems, isFetchingStats])
  };
};
